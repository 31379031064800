.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*=========================header=========================*/

.header {
    /*position: absolute;*/
    width: 100%;
    height: 55px;
    left: 0px;
    top: 0px;
    background: linear-gradient(270.84deg, #070707 76.45%, rgba(7, 7, 7, 0) 99.29%);
    margin-bottom: 80px;
}

.header-inner {
    width: calc(100% - 210px);
    margin: auto;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/*=========================body=========================*/
.body-inner {
    padding-bottom: 20px;
}

.body {
    width: calc(100% - 210px);
    margin: auto !important;
}

.calculation-section {
    width: 100%;
    height: 499px;
    /*background-color: red;*/
    display: inline-table;
    /*margin-bottom: 20px;*/
}

.mapDiv {
    height: 590px;
    max-height: 590px;
}
.map {
    width: 100%;
    /*height: 499px;*/
    height: auto;
    max-height: 590px;
    /*background-color: blue;*/

}

/*map*/
.App {
    height: 100vh;
    width: 100vw;
}

.map-container {
    height: 100%;
    width: 100%;
}

/*form*/
.main-form {

}

.hotel-type-container {

}

.hotel-type-title {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 9px;
}

.designations-container {

}

.designations-title {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;
}

.details-container {
    max-width: 475px;
}

.details-title {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-top: 23px;
    margin-bottom: 18px;
}

.designations-holder {
    max-width: 475px;
    margin-left: 7px;
}

.designations-holder > ul {
    justify-content: space-between;
}

.designations-holder > ul > li > span > span > span {
    background-color: unset;
    border: unset;
}

.designations-holder > ul > li > span > span > span:hover {
    background-color: unset;
}

.button-container {

}

.form-inner-sub {
    display: flex;
    justify-content: space-between;
}

.form-inner-sub .mb-3 {
    width: calc(50% - 5px);
}

.details-container > form > div.mb-3 {
    margin-bottom: 7px !important;
}
.details-container > form > div > div.mb-3 {
    margin-bottom: 7px !important;
}

.details-container > form > div > div.mb-3 > div.mb-3 {
    margin-bottom: 7px !important;
}

.details-container > form > div > input {
    height: 35px;
    background: #F2F2F2;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.details-container > form > div > div > div > input {
    height: 35px;
    background: #F2F2F2;
    border-radius: 3px;
    border: 1px solid #D8D8D8;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.genarate-btn {
    height: 35px;
    width: 100%;
    background: #6EDE47 !important;
    border: none !important;
    border-radius: 3px !important;
    margin-top: 15px;
    /*margin-bottom: 20px;*/
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #085C25 !important;
}

.genarate-btn:hover {
    background-color: #5ED733FF !important;
}

.designation-item {
    width: calc(50% - 6px);
}

.result-container-hidden {
    min-height: 52px;
    background: transparent;
}
.contact-hidden {
    min-height: 20px;
    background: transparent;
}
.contact-p-hidden {
    margin-bottom: 0;
    margin-top: 15px;
    background: transparent;
}
.result-container {
    min-height: 52px;
    background: rgba(110, 222, 71, 0.8);
    border-radius: 7px;
    max-width: 475px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 17px;
}

.result-container-txt-contain {
    height: 19px;
    margin-left: 19px;
    margin-top: 23px;
}

.result-container-price-contain {
    /*height: 44px;*/
    margin-right: 16px;
    margin-top: 16px;
}

.result-container-txt {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #085C25;
    margin-bottom: unset;
}

.result-container-price-contain-txt {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #085C25;
    margin-bottom: unset;
}

.contact {
    max-width: 475px;
    text-align: center;
}

.whatsapliink {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #B90808;
}
/*error messages*/
.frm-error {
    width: 100%;
    font-family: 'Inter';
    margin-top: 3px;
    font-size: 11px;
    color: #dc3545;
    text-align: left;
}
@media (max-width: 991px) {
    .result-container {
        max-width: unset;
    }
    .contact {
        max-width: unset;
    }

    .designations-holder {
        max-width: unset;
    }

    .details-container {
        max-width: unset;
    }

    .designation-item {
        width: calc(33% - 6px);
    }

    .map {
        min-height: 400px;
        margin-bottom: 30px;
    }
    .mapDiv {
        height: 400px;
    }
    .header {
        margin-bottom: 30px;
    }
}

@media (max-width: 769px) {
    .designation-item {
        width: calc(50% - 6px);
    }

    .header-inner {
        width: calc(100% - 48px);
    }

    .body {
        width: calc(100% - 48px);
    }
}

@media (max-width: 450px) {
    .designation-item {
        width: 100%;
    }

    .map {
        min-height: 300px;
        margin-bottom: 30px;
    }
    .mapDiv {
        height: 300px;
    }
    .form-inner-sub {
        flex-direction: column;
    }

    .form-inner-sub .mb-3 {
        width: 100%;
    }
    .form-inner-sub .mb-3  .mb-3 {
        width: 100%;
    }
}